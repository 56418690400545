<template>
  <div>
    <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="560px" @closed="handleClose">
      <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
               label-width="auto">
        <el-form-item label="箱子：" prop="jhb_express_box_id">
          <el-select v-model="formData.jhb_express_box_id" placeholder="请选择" clearable style="width: 220px" @change="changeBox" >
               <el-option v-for="item in jhbDataList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="容量：">
          <el-input v-model="checkedJHB.volume" readonly style="width: 150px;"></el-input>
		  <p>扎/箱</p>
        </el-form-item>
		<el-form-item label="集花宝预收费：">
          <el-input v-model="checkedJHB.fee" readonly style="width: 150px;"></el-input>
		  <p>元/箱</p>
        </el-form-item>
		<el-form-item label="本系统加收费用：" prop="add_fee">
          <el-input v-model="formData.add_fee" style="width: 150px;"></el-input>
		  <p>元/箱</p>
        </el-form-item>
		 <p >最终预收用户费用：{{getCustomerPrice()}}元 /箱</p>

      </el-form>
      <template #footer>
        <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
        <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
      </template>
    </ly-dialog>
  </div>
</template>

<script>
import {ExpressBoxAdd, ExpressBoxEdit} from "@/api/api";
import { loadJHBExpressBoxListApi,newExpressBoxApi, expressBoxEditApi, expressBoxAddApi} from "@/api/express";
import LyDialog from "@/components/dialog/dialog";
import {deepClone} from "@/utils/util"
import TEditor from "@/components/TEditor.vue";

export default {
  name: "addExpressBox",
  components: {TEditor, LyDialog},
  emits: ['refreshData', 'closed'],
  data() {
    return {
      dialogVisible: false,
      loadingSave: false,
      loadingTitle: '',
	  jhbDataList:[],
	  checkedJHB:{},
      formData: {
		id:0,
		jhb_express_box_id:0,
		add_fee:0,
      },
      rules: {
        jhb_express_box_id: [
          {required: true, message: '请选择标准箱', trigger: 'blur'}
        ]
      },
      areaList: [],
    }
  },
  methods: {
    handleClose() {
      this.$emit('closed')
    },
    handleOpen(item, flag) {
      this.loadingTitle = flag
      this.dialogVisible = true
	  
      if (item) {
        let data=deepClone(item)
		this.formData.id=data.id
		this.formData.jhb_express_box_id=data.jhb_express_box_id
		this.formData.add_fee=data.add_fee
		
		
      } else {
        // this.rules.password = [
        //     { required: true, message: '请输入密码', trigger: 'blur' }
        // ]
      }
	  this.loadJHBExpressBoxList()
    },
	getCustomerPrice(){
		let price="0.0";
		if(this.checkedJHB && this.checkedJHB.id){
			
			let new_price=parseFloat(this.checkedJHB.fee) + parseFloat(this.formData.add_fee) 
			price=new_price.toFixed(2)
		}
		console.log("price:",price)
		return price
	},
	changeBox(e){
		let checkdList=this.jhbDataList.filter(item=>{
			return e==item.id
		})
		if (checkdList.length>0){
			this.checkedJHB=checkdList[0]
			
		}
		console.log("this.:",this.checkedJHB,"___",)
		
	},
	loadJHBExpressBoxList(){
		loadJHBExpressBoxListApi({page:1,limit:99}).then(res=>{
			if(res.code==1){
				this.jhbDataList=res.data.data_list
				if(this.formData.jhb_express_box_id>0){
					this.changeBox(this.formData.jhb_express_box_id)
				}
				
			}else{
				this.$message.warning(res.msg)
			}
		})
	},
    submitData() {
		
      this.$refs['rulesForm'].validate(obj => {
        if (obj) {
          this.loadingSave = true
          let param = {
            ...this.formData
          }
            param.add_fee=this.formData.add_fee.toString()
			
            newExpressBoxApi(param).then(res => {
              this.loadingSave = false
              if (res.code == 1) {
                this.$message.success(res.msg)
                this.dialogVisible = false;
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          

        }
      })
    },
  }
}
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 128px;
  height: 128px;
  line-height: 128px;
  text-align: center;
}

.avatar {
  width: 128px;
  height: 128px;
  display: block;
}
</style>

