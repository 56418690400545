<template>
	<div :class="{ 'ly-is-full': isFull }">
		
		<div class="tableSelect" ref="tableSelect">
			
				<!-- <div style="margin-left: 15px;">
					<p>授信额度： <el-tag>{{totalBalance}}元</el-tag></p>
				</div> -->
				<div style="margin-left: 15px; margin-top: 4px;display: flex;" >
					<p>剩余额度： <el-tag style="color: red;">{{getMoneyStr(leftBalance) }}元</el-tag></p>
					<p style="margin-left: 10px;font-size: 12px;margin-top: 4px;">剩余额度小于0或用户下单时扣费项目总金额大于剩余额度时小程序用户将不能下单，请联系集花宝财务及时充值</p>
				</div>
			
			<el-form :inline="false" :model="formInline" label-width="auto">
				<div style="width: 100%;">
					<el-row :gutter="20">
						<el-col :span="4">
							<el-form-item label="订单编号：">
								<el-input style="width:100%" v-model.trim="formInline.order_id" maxlength="60" clearable
									placeholder="订单编号" @change="search"></el-input>
							</el-form-item>
						</el-col>

						<el-col :span="6">
							<el-form-item label="订单日期：">
								<el-date-picker style="width:100%" v-model="timers" type="daterange"
									@change="timeChange" range-separator="至" start-placeholder="开始时间"
									end-placeholder="结束时间">
								</el-date-picker>
							</el-form-item>
						</el-col>
						
						<el-col :span="6">
							<el-form-item label="">
								<el-button @click="search" type="primary" icon="Search"
									>查询
								</el-button>
								<el-button @click="handleEdit('', 'reset')" icon="Refresh">重置</el-button>
							</el-form-item>
						</el-col>
					</el-row>
				
						
					
				</div>
			</el-form>
		</div>
		<div>
			<el-tabs v-model="activeName" type="card" @tab-click="handleClick">
				<el-tab-pane label="扣费记录" name="charge">扣费记录</el-tab-pane>
				<el-tab-pane label="充值记录" name="recharge">充值记录</el-tab-pane>
			</el-tabs>
		</div>

		<div style="margin-top: 4px;">
			<!-- <div class="table"> -->
			<el-table :height="tableHeight-25" border default-expand-all row-key="order_id" :data="all_order_list"
				ref="tableref" v-loading="loadingPage" >
				<!-- <el-table-column type="selection" width="55" align="center"></el-table-column> -->
				<!-- <el-table-column width="120" prop="id" label="序号"></el-table-column> -->
				<el-table-column type="index" width="60" align="center" label="序号"></el-table-column>
				<el-table-column  width="120" prop="change_type_text" align="center" label="变动原因"></el-table-column>
				<el-table-column  width="120" prop="money" align="center" label="变动金额"></el-table-column>
				<el-table-column  width="150" prop="product_name" align="center" label="关联花材" show-overflow-tooltip></el-table-column>
				
				<el-table-column width="590" prop="order_id_list" label="订单编号" show-overflow-tooltip align="left">
					<template #default="scope">
						<div style="display: flex;color: blue;">
							<div v-for="order_id in scope.row.order_id_list" :key="order_id">
								<u @click="toDetail(scope.row,order_id)" >{{order_id}}</u>
							</div>
						</div>
						
					</template>
				</el-table-column>
			
				
				<el-table-column width="120" prop="order_date" label="订单日期"></el-table-column>
				<!-- <el-table-column width="100" prop="express_name" fixed="right" label="物流" show-overflow-tooltip></el-table-column> -->
				
			</el-table>
			<div>
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="formInline.page" :page-sizes="[ 10, 15, 20,30]" :page-size="10"
					layout="total, sizes, prev, pager, next, jumper" :total="formInline.total">
				</el-pagination>
			</div>


		</div>
		 <orderDetail ref="addModuleDetailFlag" v-if="isDetailDialogVisible"
		 	@closed="isDetailDialogVisible = false">
		 </orderDetail>
		
		
	</div>
</template>
<script>

	
	import Pagination from "@/components/Pagination";
	import orderDetail from "../components/orderDetailModule";
	
	import {
		dateFormats,
		getTableHeight,
		hasPermission
	} from "@/utils/util";
	import {
		fmallOrder,
		fmallOrderAgentSend,
		fmallOrderDelete,
		getOrderListApi,
		getOrdersAgentListApi,
        cancelOrderApi,
		setOrderApplyAfterStatusAPi,
		
	} from '@/api/api'
	
	import{loadPrintListApi,loadCustomerOrdersPrintDataApi} from '@/api/print'
	import{exprotOrderExpressApi} from '@/api/express'
	import{loadFundChangeRecordsApi} from '@/api/finance'
	import {getExportOrdersPressUrl} from '@/api/export'
	
	import {setStorage,getStorage} from '@/utils/util'
import axios from "axios";

	export default {
		components: {
			Pagination,
		    	orderDetail,
		},
		name: 'JHBBalanceChangeRecordManage',
		data() {
			return {
				isDetailDialogVisible:false,
				activeName: 'charge',
				
				totalBalance:0.0,
				leftBalance:"0.0",
				is_show_package: false,

				isFull: false,
				tableHeight: 460,
				loadingPage: false,
				formInline: {
					
					page: 1,
					limit: 30,
					total: 0,
					start_time: "",
					end_time: "",
					
					data_type:"charge",
				},
				defaultImg: require('../../assets/img/avatar.jpg'),
				pageparm: {
					page: 1,
					limit: 30,
					total: 0
				},
				statusList: [
					// {
					// 	id: 10,
					// 	name: '待支付'
					// },
					{
						id: 20,
						name: '待入位'
					},
					{
						id: 30,
						name: '待发货'
					},
					{
						id: 40,
						name: '待收货'
					},
					{
						id: 50,
						name: '待评价'
					},
					{
						id: 60,
						name: '已完成'
					},
					// {
					// 	id: 70,
					// 	name: '已取消'
					// },
				],
				typeList: [{
						id: 1,
						name: '零售'
					},
					{
						id: 2,
						name: '秒杀'
					},
					{
						id: 3,
						name: '团购'
					},
				],
				timers: [],
				tableData: [],

				isDialogVisible: false,
				isYunfeiDialogVisible: false,
				isDetailDialogVisible: false,
				isRecordlDialogVisible: false,
				showSendOutGoodsDialog: false,
				show_agent_order_detail:false,
				// 选项框选中数组
				ids: [],
				// 选项框非单个禁用
				single: true,
				// 非多个禁用
				multiple: true,

				all_order_list: [],
				agent_list: [],
				normal_order_list: [],
				agent_order_list: [],
				select_agent: {
					name: ""
				},

				agentQuery: {
					agent_total: 0,
					page: 1,
					limit: 15
				},
				select_id_list:[],
				is_show_update_pickupway:false,

			}
		},

		methods: {
			hasPermission,
			// 表格序列号
			getIndex($index) {
				// (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
				return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
			},
			getMoneyStr(balance){
				let money=parseFloat(balance)
				return money.toFixed(2)
			},
			selectAgent(row, colunm, event) {

				this.formInline.agent_id = row.agent_id
				this.select_agent.name = row.agent_name
				this.getData()
			},
			toDetail(item,order_id){
				if(item.change_type==1){
					//花材费
					this.isDetailDialogVisible = true
					this.$nextTick(() => {
						this.$refs.addModuleDetailFlag.handleOpened(order_id)
					})
				}
				if(item.change_type==2 || item.change_type==3 || item.change_type==4){
					//运费、装箱费、打包费
				}
			},
			setOrderApplyStatus(item){
				
				let msg="确定同意用户:"+item.address_name+" 的订单延长售后申请时间吗？该订单订单编号："+item.order_id+",请确定后再操作！"
				let vm = this
				vm.$confirm(msg, {
					closeOnClickModal: false
				}).then(res => {
					setOrderApplyAfterStatusAPi({
						order_id: item.order_id
					}).then(res => {
						if (res.code == 2000) {
							vm.$message.success(res.msg)
							
						} else {
							vm.$message.warning(res.msg)
						}
					})
				}).catch(() => {
				
				})
				
			   
			},
			handleClick(tab, event) {
                console.log("table:",tab)
				this.activeName = tab.props.name
				this.formInline.data_type=tab.props.name
				console.log("data_type:",this.formInline.data_type)
				this.getData()

			},
			
			setFull() {
				this.isFull = !this.isFull
				window.dispatchEvent(new Event('resize'))
			},
			//多选项框被选中数据
			
			handelAddClick() {
				this.isDialogVisible = true
				this.$nextTick(() => {
					this.$refs.addModuleFlag.handleOpen(null, "新增")
				})
			},
			fmallOrderAgentSend(ids) {
				fmallOrderAgentSend({
					ids: ids
				}).then(res => {
					if (res.code == 2000) {
						this.$message.success(res.msg)
						this.search()
					} else {
						this.$message.warning(res.msg)
					}
				})
			},
			handleOrderDetail(item){
				this.isDetailDialogVisible = true
				this.$nextTick(() => {
					this.$refs.addModuleDetailFlag.handleOpen(item, "订单详情", "normal")
				})
			},
			cancelOrder(item){
				let msg="确定取消用户:"+item.address_name+"的订单吗？该订单订单编号："+item.order_id+",请确定后再操作！"
				let vm = this
				vm.$confirm(msg, {
					closeOnClickModal: false
				}).then(res => {
					cancelOrderApi({
						order_id: item.order_id
					}).then(res => {
						if (res.code == 2000) {
							vm.$message.success(res.msg)
							
						} else {
							vm.$message.warning(res.msg)
						}
					})
				}).catch(() => {
				
				})
			},
			handleEdit(row, flag) {
				let act_type = ""
				if (this.activeName == "agent") {
					act_type = "agent"
				}
				if (this.activeName == "normal" || this.activeName=="all") {
					act_type = "normal"
				}

				if (flag == 'detail') {
					if(act_type=="agent"){
						this.show_agent_order_detail=true;
						this.$nextTick(()=>{
							this.$refs.agentOrderDetailRef.handleOpen(row.id,row.agent_name+"_代理商订单详情"+"("+this.formInline.order_date+")",this.formInline.order_date)
						})
					}else{
						this.isDetailDialogVisible = true
						this.$nextTick(() => {
							this.$refs.addModuleDetailFlag.handleOpen(row, "订单详情", act_type)
						})
					}
					
				

				} else if (flag == 'agentSend') {
					this.fmallOrderAgentSend([row.id])
				} else if (flag == 'ruwei') {
					this.$router.push({
						name: 'InsertionManage',
						query: {
							order_id: row.order_id
						}
					});
				} else if (flag == 'record') {
					this.isRecordlDialogVisible = true
					this.$nextTick(() => {
						this.$refs.addModuleRecordFlag.handleOpen(row, "操作记录")
					})
				}  else if (flag == 'printOrder' || flag == 'agentPrintSelf') {
					this.$refs.modulePrintOrderFlog.handleOpen(row)
				} else if (flag == 'mPrintOrder') {
					this.$refs.moduleMPrintOrderFlog.handleOpen(this.ids)
				} else if (flag == 'agentPrintOrder') {
					const ids = [row.id]
					row.children.forEach(item => ids.push(item.id))
					this.$refs.moduleMPrintOrderFlog.handleOpen(ids)
				} else if (flag == "weight") {

					this.is_show_package = true
					let act_type = ""
					if (this.activeName == "agent") {
						act_type = "agent"
					}
					if (this.activeName == "normal") {
						act_type = "normal"
					}
					this.$nextTick(() => {
						this.$refs.packageModuleDialog.handleOpen(row, "weight", act_type,this.formInline.order_date)
					})
				} else if (flag == "package") {

					this.is_show_package = true
					this.$nextTick(() => {
						let act_type = ""
						if (this.activeName == "agent") {
							act_type = "agent"
						}
						if (this.activeName == "normal") {
							act_type = "normal"
						}
						this.$refs.packageModuleDialog.handleOpen(row, "package", act_type,this.formInline.order_date)
					})
				} else if (flag == 'delete') {
					let vm = this
					vm.$confirm('您确定要删除选中的数据吗？', {
						closeOnClickModal: false
					}).then(res => {
						fmallOrderDelete({
							id: row.id
						}).then(res => {
							if (res.code == 2000) {
								vm.$message.success(res.msg)
								vm.search()
							} else {
								vm.$message.warning(res.msg)
							}
						})
					}).catch(() => {

					})
				} else if (flag == "reset") {
					this.formInline = {
						page: 1,
						limit: 10
					}
					this.pageparm = {
						page: 1,
						limit: 10,
						total: 0
					}
					 this.getNowDate()
					this.timers = []
					this.getData()
				}
			},
			/**
			 * 从URL里下载文件
			 */
			// 下载文件
			downloadFileURL(url) {
				var iframe = document.createElement("iframe")
				iframe.style.display = "none";
				iframe.src = url;
				document.body.appendChild(iframe);
			},
			exportDataBackend() {
				var params = {
					page: 1,
					limit: 9999,
				}
				// UsersUsersExportexecl(params).then(res => {
				//     if (res.code == 2000) {
				//         this.downloadFileURL(res.data.data)
				//         //this.$message.warning(res.data.data)
				//     }
				// })
			},
			callFather(parm) {
				this.formInline.page = parm.page
				this.formInline.limit = parm.limit
				this.getData()
			},
			search() {
				this.formInline.page = 1
				this.formInline.limit = 10
				this.getData()
			},
			//获取列表
			async getData() {

				
				this.loadingPage = true
				if(this.timers){
					this.formInline.start_time = dateFormats(this.timers[0], 'yyyy-MM-dd');
					this.formInline.end_time = dateFormats(this.timers[1], 'yyyy-MM-dd');
				}
				
				if(this.formInline.start_time){
					this.formInline.order_date=''
				}
				

				loadFundChangeRecordsApi(this.formInline).then(res => {
					this.loadingPage = false
					
					if (res.code == 1) {
						let data = res.data
						this.all_order_list = res.data.data_list
						this.leftBalance=res.data.left_balance
						this.formInline.total = res.data.total;
					}else{
						this.$message(res.msg)
					}
				})
			},

			timeChange(val) {
				if (val) {
					this.formInline.start_time = dateFormats(val[0], 'yyyy-MM-dd');
					this.formInline.end_time = dateFormats(val[1], 'yyyy-MM-dd');
					this.order_date=""
				} else {
					this.formInline.start_time = null
					this.formInline.end_time = null
				}
				this.search()
			},
			// 计算搜索栏的高度
			listenResize() {
				this.$nextTick(() => {
					this.getTheTableHeight()
				})
			},
			getTheTableHeight() {
				let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
				tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
				this.tableHeight = getTableHeight(tabSelectHeight) - 40
			},

			//分页相关
			handleSizeChangeAgent(val) {
				this.agentQuery.limit = val
				this.getData()
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChangeAgent(val) {
				this.agentQuery.page = val
				this.getData()
				console.log(`当前页: ${val}`);
			},

			handleSizeChange(val) {
				this.formInline.limit = val
				this.getData()
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				this.formInline.page = val
				this.getData()
				console.log(`当前页: ${val}`);
			},
			selectDate(e) {
				let timestamp = e.getTime()
				const dateObj = new Date(timestamp); // 创建Date对象
				const year = String(dateObj.getFullYear()); // 获取年份
				const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // 获取月份，并补零
				const day = String(dateObj.getDate()).padStart(2, "0"); // 获取日期，并补零
				this.formInline.order_date = year + "-" + month + "-" + day
				// console.log("select_order_date", e.getTime(), "__", )
			},
			getNowDate() {
				let dateObj = new Date();
				
				let hour=dateObj.getHours()
				let minutes=dateObj.getMinutes()
				
				
				if(hour<17){
					dateObj.setDate(dateObj.getDate()-1)
				}
				
				const year = String(dateObj.getFullYear()); // 获取年份
				const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // 获取月份，并补零
				const day = String(dateObj.getDate()).padStart(2, "0"); // 获取日期，并补零
				
				this.formInline.order_date = year + "-" + month + "-" + day
				// this.formInline.order_date = "2024-12-17"
				this.timers.push(this.formInline.order_date)
				this.timers.push(this.formInline.order_date)
			},
			agentPrint(agent_id){
				this.select_id_list=[]
				this.select_id_list.push(agent_id)
				this.printBatchData()
			},
			normalPrint(parent_id){
				this.select_id_list=[]
				this.select_id_list.push(parent_id)
				this.printBatchData()
			},
			printCustomerOrders(item_id){
				this.select_id_list=[]
				this.select_id_list.push(item_id)
				this.openCustomerPrintDialog()
			},
			openCustomerPrintDialog(){
				this.$refs.customerPrintDialog.handleOpen({id_list:this.select_id_list,order_date:this.formInline.order_date,data_type:this.activeName})
			},
			printBatchData(){
				// this.$refs.moduleMPrintOrderFlog.handleOpen({id_list:this.select_id_list,order_date:this.formInline.order_date})
				this.$refs.modulePrintOrderFlog.handleOpen({id_list:this.select_id_list,order_date:this.formInline.order_date,data_type:this.activeName})
				// this.printData(this.select_id_list)
			},
			printData(data_list){
			
				if(data_list.length<1){
					this.$message("请先选择要打印的数据")
					return
				}
				
				loadPrintListApi({
					id_list:JSON.stringify(data_list) ,
					order_date:this.formInline.order_date
				}).then(res=>{
					if(res.code==2000){
						console.log(res.data)
					}
				})
			},
			 async exportOrder(){
				 // console.log("url:",getExportOrdersPressUrl())
				 let token=getStorage('logintoken')
				const response=await axios({
					url:getExportOrdersPressUrl(),
					headers:{
					    Authorization: 'JWT ' + token,
					},
					method:"GET",
					params:{order_date:this.formInline.order_date},
					responseType:'blob',
				})
				
				const url=window.URL.createObjectURL(new Blob([response.data]));
				const link=document.createElement('a');
				link.href=url;
				link.setAttribute('download',this.formInline.order_date+"_物流交接信息.xls")
				document.body.append(link);
				link.click()
				this.$message("请检查下载进度")
				// exprotOrderExpressApi({order_date:this.formInline.order_date}).then(res=>{
					
				// })
			}
		

	},
	created() {
		    this.getNowDate()
			this.getData()
		},
		mounted() {
			// 监听页面宽度变化搜索框的高度
			window.addEventListener('resize', this.listenResize);
			this.$nextTick(() => {
				this.getTheTableHeight()
			})
		},

		unmounted() {
			// 页面销毁，去掉监听事件
			window.removeEventListener("resize", this.listenResize);
		},
	}
</script>
<style>
	.el-table .warning-row {
		background: oldlace;
	}

	.el-table .success-row {
		background: #f0f9eb;
	}
</style>