<template>
    <div :class="{ 'ly-is-full': isFull }">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item label="">
					<el-button @click="search" type="primary" icon="Search"
						>查询
					</el-button>
					<el-button @click="handleEdit('', 'add')" type="primary" icon="Plus"
                       >新增</el-button></el-form-item>
            </el-form>
        </div>
		<div style="margin-top: 12px;">
			<p style="margin-left: 6px;margin-bottom: 8px;font-size: 12px;color: red;">说明：分销推广关系最多可到二级，具体使用几级系统根据您的配置判断，如只设置且启用了一级下线返佣计算配置则只建立一级推广关系，有且启用了二级下线的返佣配置则会建立二级推广关系，
			没有启用返佣配置则表示不开启分销功能！</p>
		</div>

        <div class="table">
            <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage">
                <el-table-column  width="100" align="center" label="序号" >
                   <template #default="scope">
                        <span v-text="getIndex(scope.$index)"></span>
                    </template>
                </el-table-column>
                <!-- <el-table-column min-width="100" prop="phone" label="账号"></el-table-column> -->
                <el-table-column width="220"  label="适用等级"  prop="level_text" align="center">
				</el-table-column>
                <el-table-column width="150" prop="fee_type_text" label="返佣计算方式"></el-table-column>
                <el-table-column width="250" prop="compute_value_text" label="返佣计算值" align="center"></el-table-column>
                <el-table-column width="250" prop="min_product_count" label="最低花材数量" align="center"></el-table-column>
                <el-table-column width="150" prop="max_fee" label="最高返佣" align="center"></el-table-column>
                <el-table-column width="150" prop="article_title" label="说明" align="center" show-overflow-tooltip style="color: blue;"></el-table-column>
                
                <el-table-column width="150" label="状态">
                    <template #default="scope">
                        <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949"
                            @change="changeStatus(scope.row)">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" >
                    <template #header>
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div>操作</div>
                            <div @click="setFull">
                                <el-tooltip content="全屏" placement="bottom">
                                    <el-icon><full-screen /></el-icon>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                    <template #default="scope">
                        <span class="table-operate-btn" @click="handleEdit(scope.row, 'edit')"
                            v-show="hasPermission(this.$route.name, 'Update')">编辑</span>
                        <span class="table-operate-btn" @click="manageStatus(scope.row, 'del')"
                            v-show="hasPermission(this.$route.name, 'Delete')">删除</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        
	
		
		<el-dialog :title="dialogTitle"  v-model="showDialog" width="30%">
			<el-form ref="dataForm" label-width="150px">
				<el-form-item label="小程序名称" prop="app_name">
					<el-radio-group v-model="dataForm.fee_type">
						<el-radio :label="0">按比例计算</el-radio>
						<el-radio :label="1">固定佣金</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item v-if="dataForm.fee_type==0" label="计算比例" >
					<el-input v-model="dataForm.compute_value" style="width: 100px;"></el-input>
					<p>% ,按订单内实际发货的花材总额计算</p>
				</el-form-item>
				<el-form-item v-if="dataForm.fee_type==1" label="返佣金额" >
					<el-input v-model="dataForm.compute_value" style="width: 100px;"></el-input>
					
				</el-form-item>
				<el-form-item v-if="dataForm.fee_type==0" label="最高返佣" >
					<el-input v-model="dataForm.max_fee" style="width: 100px;"></el-input>
					<p style="margin-left: 4px;">元，单笔返佣超过该值将按最高值返佣</p>
				</el-form-item>
				<el-form-item label="适用对象" prop="level">
					<el-radio-group v-model="dataForm.level">
						<el-radio :label="1">一级下线</el-radio>
						<el-radio :label="2">二级下线</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="最低花材数量" prop="min_product_count">
					<el-input v-model="dataForm.min_product_count" style="width: 100px"></el-input>
				</el-form-item>
				<el-form-item label="关联说明文章" prop="article_id">
					<el-select v-model="dataForm.article_id" placeholder="请选择关联的说明文章">
						<el-option v-for ="item in article_list" :key="item.id" :label="item.title" :value="item.id"></el-option>
					</el-select>
					
				</el-form-item>
			   
				<el-form-item >
					<el-button  @click="showDialog=false">关闭</el-button>
					<el-button type="primary" @click="save">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		
		
		
		
    </div>
</template>
<script>

import Pagination from "@/components/Pagination";
import {  getTableHeight } from "@/utils/util";

import { loadPromoteConfigListApi,newPromoteConfigApi,mangePromoteConfigStatusApi } from '@/api/commission'
import { loadAppListApi,addAppApi,editAppApi,manageAppStatusApi } from '@/api/app_manage'
import { loadArticleListApi } from '@/api/article'

export default {
    components: {
        Pagination,

    },
    name: 'promoteFeeConfigManage',
    data() {
        return {
            isFull: false,
            tableHeight: 500,
            loadingPage: false,
            formInline: {
                page: 1,
                limit: 10,
            },
            
            pageparm: {
                page: 1,
                limit: 10,
                total: 0
            },
            statusList: [
                { id: 1, name: '正常' },
                { id: 0, name: '禁用' }
            ],
           
            timers: [],
            tableData: [],
            isDialogVisible: false,
			
			
			show_edit_super_dialog:false,
			salesman_title:"",
			showDialog:false,
			salesman_act_type_list:[{"name":"新增"},{"name":"编辑"}],
			dataForm:{
				id:0,
				fee_type:0,
				compute_value:0,
				min_product_count:1,
				max_fee:999,
				level:-1,
				article_id:0,
				
			},
			article_list:[],
			admin_list:[],
			
			
			
			send_type_title:"",
			show_update_send_type:false,
			
			dialogTitle:"推广返佣设置"
			
			
        }
    },

    methods: {
		
		save(){
			
			let msg="确定提交填写的内容吗？"
			
			var that=this
			that.$confirm(msg,"提示",{
				closeOnClickModal: false,
				type: "warning"
			}).then(res=>{
				    let params=that.dataForm
					params.min_product_count=parseInt(that.dataForm.min_product_count)
					params.level=parseInt(that.dataForm.level)
					newPromoteConfigApi(params).then(res=>{
						if(res.code==1){
							that.$message.success(res.msg)
							that.getData()
							that.showDialog=false
						}else{
							that.$message.error(res.msg)
						}
					})
				
				
				
			}).catch(()=>{
				
			})
				
			
			
		},
		
		
        // 表格序列号
        getIndex($index) {
            // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
            return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
        },
        setFull() {
            this.isFull = !this.isFull
            window.dispatchEvent(new Event('resize'))
        },
		
		
        changeStatus(row) {
            let that = this
            
			let act_type=""
			let msg=""
			if(row.status){
				act_type="start"
				msg="确定启用该返佣设置吗？启用后用户下单返佣将按该设置计算，不影响启用前已下单的返佣计算"
			}else{
				act_type='stop'
				msg="确定停用该返佣设置吗？！"
			}
			
			
            that.$confirm(msg, '提醒', {
                closeOnClickModal: false,
                type: "warning"
            }).then(res => {
               
                mangePromoteConfigStatusApi({ id: row.id ,act_type:act_type}).then(res => {
                    if (res.code == 1) {
                        that.$message.success(res.msg)
                        that.getData()
                    } else {
						row.status=!row.status
                        that.$message.warning(res.msg)
                    }
                })
            }).catch(() => {

            })
        },
        
        handleEdit(row, flag) {
			this.getArticleList()
			if(flag=="edit"){
				this.showDialog=true
				this.dataForm.id=row.id
				this.dataForm.fee_type=row.fee_type
				this.dataForm.compute_value=row.compute_value
				this.dataForm.min_product_count=1
				this.dataForm.max_fee=row.max_fee
			    this.dataForm.level=row.level
				this.dataForm.article_id=row.article_id
				this.dialogTitle="编辑推广返佣设置"
				return
			}
			if(flag=="add"){
				this.showDialog=true
				this.dataForm.id=0
				this.dataForm.fee_type=0
				this.dataForm.compute_value=0
				this.dataForm.min_product_count=1
				this.dataForm.max_fee=9999
				
				this.dialogTitle="新增推广返佣设置"
				return
			}
			
            
           
        },
		
		
        /**
         * 从URL里下载文件
        */
        // 下载文件
        downloadFileURL(url) {
            var iframe = document.createElement("iframe")
            iframe.style.display = "none";
            iframe.src = url;
            document.body.appendChild(iframe);
        },
        exportDataBackend() {
            var params = {
                page: 1,
                limit: 9999,
            }
            // UsersUsersExportexecl(params).then(res => {
            //     if (res.code == 2000) {
            //         this.downloadFileURL(res.data.data)
            //         //this.$message.warning(res.data.data)
            //     }
            // })
        },
        callFather(parm) {
            this.formInline.page = parm.page
            this.formInline.limit = parm.limit
            this.getData()
        },
        search() {
            this.formInline.page = 1
            this.formInline.limit = 10
            this.getData()
        },
        //获取列表
        async getData() {
            this.loadingPage = true
            loadPromoteConfigListApi(this.formInline).then(res => {
                this.loadingPage = false
                if (res.code == 1) {
					
                    this.tableData = res.data.data_list
                    
                    this.pageparm.total = res.data.total;
                }
            })
        },

      
        // 计算搜索栏的高度
        listenResize() {
            this.$nextTick(() => {
                this.getTheTableHeight()
            })
        },
        getTheTableHeight() {
            let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
            tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
            this.tableHeight = getTableHeight(tabSelectHeight)
        },
		getArticleList(){
			loadArticleListApi({status:1,page:1,limit:99}).then(res=>{
				if(res.code==1){
					this.article_list=res.data.data_list
				}
			})
		},
		manageStatus(row,flag){
			
		}

    },
    created() {
        this.getData()
		
    },
    mounted() {
        // 监听页面宽度变化搜索框的高度
        window.addEventListener('resize', this.listenResize);
        this.$nextTick(() => {
            this.getTheTableHeight()
        })
    },

    unmounted() {
        // 页面销毁，去掉监听事件
        window.removeEventListener("resize", this.listenResize);
    },
}
</script>
