import axios from "axios";
import {
  reqExpost,
  ajaxGet,
  ajaxPost,
  ajaxDelete,
  ajaxPut,
  ajaxPatch,
  uploadImg,
  ajaxGetDetailByID,
} from "./request";
import { url } from "./url";

// export const login = (params) => ajaxPost({ url: `token/`, params });
export const loadCommissionListApi = (params={}) =>ajaxGet({url:'commission-rate/list', params})

export const newCommissionApi = (params={}) =>ajaxPost({url:'commission-rate/new', params})

export const mangeCommisssionStatusApi = (params={}) =>ajaxPost({url:'commission-rate/manage/status', params})

export const loadPromoteConfigListApi = (params={}) =>ajaxGet({url:'promotion/fee/config/list', params})

export const newPromoteConfigApi = (params={}) =>ajaxPost({url:'promotion/fee/config/edit', params})

export const mangePromoteConfigStatusApi = (params={}) =>ajaxPost({url:'promotion/fee/config/manage/status', params})


