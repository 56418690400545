<template>
    <div :class="{ 'ly-is-full': isFull }">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item label="内容：">
                    <el-input v-model.trim="formInline.search" maxlength="60" clearable placeholder="名称" @change="search"
                        style="width:130px"></el-input>
                </el-form-item>
               
                
                <el-form-item label="状态：">
                    <el-select size="default" v-model="formInline.is_active" placeholder="请选择" clearable style="width: 90px"
                        @change="search">
                        <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                
                <el-form-item label=""><el-button @click="search" type="primary" icon="Search"
                       >查询</el-button></el-form-item>
                <el-form-item label=""><el-button @click="handleEdit('', 'reset')"
                        icon="Refresh">重置</el-button></el-form-item>
                <el-form-item label=""><el-button @click="handleEdit('', 'add')" type="primary" icon="Plus"
                       >新增</el-button></el-form-item>
            </el-form>
        </div>
		<div>
			<p>添加文章后请启用状态，相关页面配置才可以搜索到该文章</p>
		</div>

        <div class="table">
            <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage">
                <el-table-column  width="100" align="center" label="序号" >
                   <template #default="scope">
                        <span v-text="getIndex(scope.$index)"></span>
                    </template>
                </el-table-column>
                <!-- <el-table-column min-width="100" prop="phone" label="账号"></el-table-column> -->
                <el-table-column width="320"  label="标题" >
					<template #default="scope">
						<el-text >{{scope.row.title}}</el-text>
						<!-- <el-button size="small" type="primary" style="margin-left: 8px;">详细</el-button> -->
					    <!-- <span v-if="scope.row.is_super" style="margin-left: 8px;border: 1px solid #ccc;border-radius: 4px; color:royalblue;">超代</span> -->
					</template>
				</el-table-column>
				 <el-table-column width="700"  label="内容" show-overflow-tooltip>
					<template #default="scope">
						<el-text >{{scope.row.content}}</el-text>
						<!-- <el-button size="small" type="primary" style="margin-left: 8px;">详细</el-button> -->
					    <!-- <span v-if="scope.row.is_super" style="margin-left: 8px;border: 1px solid #ccc;border-radius: 4px; color:royalblue;">超代</span> -->
					</template>
				</el-table-column>
               
                
                <el-table-column width="150" label="状态">
                    <template #default="scope">
                        <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949"
                            @change="changeStatus(scope.row)">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" >
                    <template #header>
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div>操作</div>
                            <div @click="setFull">
                                <el-tooltip content="全屏" placement="bottom">
                                    <el-icon><full-screen /></el-icon>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                    <template #default="scope">
                        <span class="table-operate-btn" @click="handleEdit(scope.row, 'edit')"
                            v-show="hasPermission(this.$route.name, 'Update')">编辑</span>
                        <span class="table-operate-btn" @click="manageStatus(scope.row, 'del')"
                            v-show="hasPermission(this.$route.name, 'Delete')">删除</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
      
		
		<el-dialog :title="dialogTitle"  v-model="showDialog" style="width: 75%;height: 400px;" >
			<el-form ref="dataForm" label-width="150px">
				<el-form-item label="标题" prop="title">
					<el-input v-model="dataForm.title" style="width: 250px;" placeholder="72字以内"></el-input>
				</el-form-item>
				<el-form-item label="正文" prop="content" >
					
						<TEditor v-model="dataForm.content"
						    :style="{height: '400'}"></TEditor>
					
				</el-form-item>
			   
				<el-form-item >
					<el-button  @click="showDialog=false">关闭</el-button>
					<el-button type="primary" @click="save">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		
		
		
		
    </div>
</template>
<script>

import Pagination from "@/components/Pagination";
import TEditor from '@/components/TEditor'
import { dateFormats, getTableHeight } from "@/utils/util";

import { operateSuperAgentAPi } from '@/api/super_agent.js'
import { updateAgentSendTypeApi } from '@/api/agent'
import { manageArticleStatusApi,editArticleApi,loadArticleListApi } from '@/api/article'
export default {
    components: {
        Pagination,
       TEditor
    },
    name: 'agentUserManage',
    data() {
        return {
            isFull: false,
            tableHeight: 500,
            loadingPage: false,
            formInline: {
                page: 1,
                limit: 10,
            },
            defaultImg: require('../../assets/img/avatar.jpg'),
            pageparm: {
                page: 1,
                limit: 10,
                total: 0
            },
            statusList: [
                { id: 1, name: '正常' },
                { id: 0, name: '禁用' }
            ],
            identityList: [
                { id: 0, name: '普通用户' },
                { id: 1, name: '会员' },
            ],
            timers: [],
            tableData: [],
            isDialogVisible: false,
			is_show_update_box:false,
			is_show_agent_area:false,
			agent_area_form:{
				province:"",
				city:"",
				county_name:""
			},
			show_edit_super_dialog:false,
			salesman_title:"",
			showDialog:false,
			
			dataForm:{
				id:0,
				title:"",
				content:"",
				
				
			},
			admin_list:[],
			
			
			send_type_title:"",
			show_update_send_type:false,
			
			dialogTitle:""
			
			
        }
    },

    methods: {
		
		save(){
			
			let msg="确定提交填写的内容吗？"
			var that=this
			that.$confirm(msg,"提示",{
				closeOnClickModal: false,
				type: "warning"
			}).then(res=>{
				
					editArticleApi(that.dataForm).then(res=>{
						if(res.code==1){
							that.$message.success(res.msg)
							that.getData()
							that.showDialog=false
						}else{
							that.$message.error(res.msg)
						}
					})
				
				
			}).catch(()=>{
				
			})
				
			
			
		},
		
		
        // 表格序列号
        getIndex($index) {
            // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
            return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
        },
        setFull() {
            this.isFull = !this.isFull
            window.dispatchEvent(new Event('resize'))
        },
		
		
        changeStatus(row) {
            let that = this
            
			
			let act_type=""
			let msg=""
			if(row.status){
				act_type="start"
				msg="确定启用该文章吗？"
			}else{
				act_type='stop'
				msg="确定停用该文章吗？停用后在相关页面将不能看到该文章！"
			}
			
            that.$confirm(msg, '提醒', {
                closeOnClickModal: false,
                type: "warning"
            }).then(res => {
               
                manageArticleStatusApi({ id: row.id ,act_type:act_type}).then(res => {
                    if (res.code == 1) {
                        that.$message.success(res.msg)
                        that.getData()
                    } else {
						row.status=!row.status
                        that.$message.warning(res.msg)
                    }
                })
            }).catch(() => {

            })
        },
        
        handleEdit(row, flag) {
			if(flag=="edit"){
				this.showDialog=true
				this.dataForm.id=row.id
				this.dataForm.title=row.title
				this.dataForm.content=row.content
				
				this.dialogTitle="修改文章"
				return
			}
			if(flag=="add"){
				this.showDialog=true
				this.dataForm.id=0
				this.dataForm.title=""
				this.dataForm.content=""
				this.dialogTitle="新增文章"
				return
			}
			
            
            if (flag == "reset") {
                this.formInline = {
                    page: 1,
                    limit: 10
                }
                this.pageparm = {
                    page: 1,
                    limit: 10,
                    total: 0
                }
				this.dataForm.id=0
				this.dataForm.title=""
				this.dataForm.content=""
				
				this.dialogTitle="新增文章"
				
                this.getData()
            }
        },
		
		
        /**
         * 从URL里下载文件
        */
        // 下载文件
        downloadFileURL(url) {
            var iframe = document.createElement("iframe")
            iframe.style.display = "none";
            iframe.src = url;
            document.body.appendChild(iframe);
        },
        exportDataBackend() {
            var params = {
                page: 1,
                limit: 9999,
            }
            // UsersUsersExportexecl(params).then(res => {
            //     if (res.code == 2000) {
            //         this.downloadFileURL(res.data.data)
            //         //this.$message.warning(res.data.data)
            //     }
            // })
        },
        callFather(parm) {
            this.formInline.page = parm.page
            this.formInline.limit = parm.limit
            this.getData()
        },
        search() {
            this.formInline.page = 1
            this.formInline.limit = 10
            this.getData()
        },
        //获取列表
        async getData() {
            this.loadingPage = true
            loadArticleListApi(this.formInline).then(res => {
                this.loadingPage = false
                if (res.code == 1) {
					console.log("res:",res)
                    this.tableData = res.data.data_list
                    
                    this.pageparm.total = res.data.total;
                }
            })
        },

        timeChange(val) {
            if (val) {
                this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
                this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
            } else {
                this.formInline.beginAt = null
                this.formInline.endAt = null
            }
            this.search()
        },
        // 计算搜索栏的高度
        listenResize() {
            this.$nextTick(() => {
                this.getTheTableHeight()
            })
        },
        getTheTableHeight() {
            let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
            tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
            this.tableHeight = getTableHeight(tabSelectHeight)
        },
		
		manageStatus(row,flag){
			
		}

    },
    created() {
        this.getData()
		
    },
    mounted() {
        // 监听页面宽度变化搜索框的高度
        window.addEventListener('resize', this.listenResize);
        this.$nextTick(() => {
            this.getTheTableHeight()
        })
    },

    unmounted() {
        // 页面销毁，去掉监听事件
        window.removeEventListener("resize", this.listenResize);
    },
}
</script>
