import axios from "axios";
import {
  reqExpost,
  ajaxGet,
  ajaxPost,
  ajaxDelete,
  ajaxPut,
  ajaxPatch,
  uploadImg,
  ajaxGetDetailByID,
} from "./request";
import { url } from "./url";

// export const login = (params) => ajaxPost({ url: `token/`, params });
export const loadAgentApplyListApi = (params={}) =>ajaxGet({url:'finance/agent/apply/list', params})
export const auditAgentApplyApi = (params={}) =>ajaxPost({url:'finance/agent/apply/audit', params})


export const loadShopApplyListApi = (params={}) =>ajaxGet({url:'finance/shop/apply/list', params})
export const auditShopApplyApi = (params={}) =>ajaxPost({url:'finance/shop/apply/audit', params})


export const exportOrdersStatiscApi = (params={}) =>ajaxPost({url:'finance/export/orders/statistic', params})

export const loadOrdersStatisticApi = (params={}) =>ajaxGet({url:'finance/orders/statistic', params})

//以下使用
export const loadFundChangeRecordsApi = (params={}) =>ajaxGet({url:'fund/change/record', params})
