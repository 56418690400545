<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="90%" top="10px" @closed="handleClose"
            :fullscreen="false" :draggable="false">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
                label-width="auto">
                <div class="ly-title">
                    订单信息
                </div>
                <el-table border :data="orderList">
                    <el-table-column width="80" prop="id" label="序号" align="left"></el-table-column>
                    <el-table-column width="180" prop="order_id" label="订单号"
                        show-overflow-tooltip align="left"></el-table-column>
                   
                    <el-table-column width="100" prop="total_product_count" label="商品总数" align="center"></el-table-column>
                    <el-table-column width="100" prop="checkin_product_count" label="实发数量" align="center"></el-table-column>
                    <el-table-column width="100" prop="total_product_money" label="总花款" align="center"></el-table-column>
                    <el-table-column width="100" prop="product_refund" label="花款退款" align="center"></el-table-column>
                    <el-table-column width="100" prop="commission" label="交易佣金" align="center"></el-table-column>
                    <el-table-column width="100" prop="commission_refund" label="佣金退款" align="center"></el-table-column>
                    
                    <el-table-column width="110" prop="paid_postage" label="已付运费" align="center" >
                    </el-table-column>
					 
					<el-table-column width="110" prop="paid_postage" label="已付装箱费" align="center" >
                    </el-table-column>
				 
					<el-table-column width="180" prop="order_remark" label="订单备注" align="left" show-overflow-tooltip >
                    </el-table-column> 
                </el-table>
				
                <div class="ly-title" style="margin-top: 10px;">
                    发货信息
                </div>
                <el-row :gutter="20">
                    <el-col :span="5">
                        <el-form-item label="发货单Id：">
                            {{ generalData.id }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="发货时间：">
                            {{ generalData.platform_send_time }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="签收时间：">
                            {{ generalData.customer_signed_time }}
                        </el-form-item>
                    </el-col>
				   <el-col :span="5">
				   	<el-form-item label="收货方式:">
				   		{{generalData.pickup_way_text}}
				   	</el-form-item>
				   </el-col>
				</el-row>
				<el-row :gutter="20">
                    <el-col :span="5">
                        <el-form-item label="已付总运费：">
                            {{
                                generalData.paid_postage
                            }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="最终运费：">
                            {{
                                generalData.real_postage
                            }}
                        </el-form-item>
                    </el-col>
					 <el-col :span="5">
                        <el-form-item label="运费差额：">
                            {{
                                generalData.shortfall_postage
                            }}
                        </el-form-item>
                    </el-col> 
					<el-col :span="5">
                        <el-form-item v-if="generalData.shortfall_postage>=0" label="运费差额是否已退用户：">
						<p v-if="generalData.shortfall_postage_status==0">已退</p>
						<p v-if="generalData.shortfall_postage_status==2">未退</p>
                           
                        </el-form-item> 
						<el-form-item v-if="generalData.shortfall_postage<0" label="用户是否已补交运费差额：">
							<p v-if="generalData.shortfall_postage_status==0">已补</p>
							<p v-if="generalData.shortfall_postage_status==1">未补</p>
                          
                        </el-form-item>
                    </el-col>
					
                </el-row> 
				<el-row :gutter="20">
                    <el-col :span="5">
                        <el-form-item label="已付装箱费：">
                            {{
                                generalData.paid_box_fee
                            }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="最终装箱费：">
                            {{
                                generalData.real_box_fee
                            }}
                        </el-form-item>
                    </el-col>
					 <el-col :span="5">
                        <el-form-item label="装箱费差额：">
                            {{  generalData.shortfall_box_fee  }}
							
                        </el-form-item>
                    </el-col> 
					<el-col :span="5">
                        <el-form-item v-if="generalData.shortfall_box_fee>=0" label="装箱费是否已退用户：">
						<p v-if="generalData.shortfall_box_fee_status==0">已退</p>
						<p v-if="generalData.shortfall_box_fee_status==2">未退</p>

                        </el-form-item>
						<el-form-item v-if="generalData.shortfall_box_fee<0" label="用户是否已补交装箱费：">
							<p v-if="generalData.shortfall_box_fee_status==0">已补</p>
							<p v-if="generalData.shortfall_box_fee_status==1">未补</p>
                           
                        </el-form-item>
                    </el-col>
					
                </el-row>
				
                <div class="ly-title"
                    v-if="!!formData.payinfo.user_refund_info && JSON.stringify(formData.payinfo.user_refund_info) != '{}'">
                    <span>用户退款信息</span>
                </div>
                <el-row :gutter="20"
                    v-if="!!formData.payinfo.user_refund_info && JSON.stringify(formData.payinfo.user_refund_info) != '{}'">
                    <el-col :span="8">
                        <el-form-item label="退款类型：">
                            {{ formData.payinfo.user_refund_info.type }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="退款时间：">
                            {{ formData.payinfo.user_refund_info.pay_time }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="退款金额：">
                            {{ formData.payinfo.user_refund_info.amount }}
                        </el-form-item>
                    </el-col>
                </el-row>
               <div class="ly-title" style="margin-top: 10px;">
                   <span>地址信息</span>
               	
               </div>
               <el-row :gutter="20">
               	<el-col :span="5">
               		<el-form-item label="收货人：">
               		    {{ addressData.address_name }}
               		</el-form-item>
               	</el-col>
               	<el-col :span="5">
               		<el-form-item label="联系电话：">
               		    {{ addressData.address_phone }}
               		</el-form-item>
               	</el-col>
               	<el-col :span="5">
               		<el-form-item label="详细地址：">
               		    {{ addressData.full_address }}
               		</el-form-item>
               	</el-col>
               	
               
               </el-row>
                <div class="ly-title">
                    物流信息
                </div>
                <el-row :gutter="20" >
                    <el-col :span="6">
                        <el-form-item label="物流公司：">
                            {{ expressData.express_name }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" >
                        <el-form-item label="物流单号：">
							<el-tag v-for="item in expressData.express_no_list" :key="item">{{item}}</el-tag>
                           <!-- {{ formData.logistics_id }}<el-button style="margin-left: 14px;" type="primary" size="small"
                                 :loading="loadingLogistics">查看物流</el-button> -->
                        </el-form-item>
                    </el-col>
                   
                   
                </el-row>
               

            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { fmallOrderDetail, orderLogisticsSearch,getOrdersDetailApi } from "@/api/api";
import { loadInvoiceOrdersDetailApi } from "@/api/invoice";
import LyDialog from "@/components/dialog/dialog";
import LyUploadAvatar from "@/components/upload/avatar";
import { getStorage } from "@/utils/util"
export default {
    name: "mergeOrderDetailModule",
    components: { LyDialog, LyUploadAvatar },
    emits: ['refreshData', 'closed'],
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingLogistics: false,
            loadingTitle: '',
            defaultImg: require('../../../assets/img/avatar.jpg'),
            id: "",
			orderList:[],
			expressData:{},
			addressData:{},
			generalData:{},
            formData: {
                payinfo: {
                    payinfo: {},
                    user_refund_info: {},
                    user_freight_refund_info: {},
                    user_lackgoods_refund_info: {},
                    user_freight_pay_info: {},
                },
                commentinfo: [],
                goods_info: [],
                logistics_id: "",
                logistics_company: "",
                logistics_company_code: "",
				
            },
            rules: {
                // username: [
                //     { required: true, message: '请输入用户名', trigger: 'blur' }
                // ],
            },
            identity: getStorage('identity')
        }
    },
    methods: {
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(item) {
            this.loadingTitle ="发货详情"
            this.dialogVisible = true
			
            if (item) {
			
                this.getOderDetail(item.id)
            }
        },
       
        getOderDetail(id) {
            loadInvoiceOrdersDetailApi({ id: id }).then(res => {
				
                if (res.code === 1) {
					console.log("res:",res.data)
					this.orderList=res.data.order_list
					this.expressData=res.data.express_data
					this.addressData=res.data.address_data
					this.generalData=res.data.merge_data
					console.log("res list:",this.orderList)
                    // this.formData = res.data
                }
            })
        },
    }
}
</script>
<style scoped>
.ly-title {
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
    font-size: 17px;
    padding-bottom: 15px;
    color: #3c4a54;
    font-weight: bold;
}
</style>

