import axios from "axios";
import {
  ajaxGet,
  ajaxPost,

} from "./request";
import { url } from "./url";

//
export const loadArticleListApi = (params) =>ajaxGet({ url: `article/list`, params });

export const editArticleApi = (params) =>ajaxPost({ url: `article/edit`, params });
export const manageArticleStatusApi = (params) =>ajaxPost({ url: `article/manage/status`, params });
  