<template>
    <div :class="{ 'ly-is-full': isFull }">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item label="查询内容：" >
                	<el-input size="default" v-model.trim="formInline.name" maxlength="60" clearable placeholder="最低商品数量"
                		@change="search" style="width:200px"></el-input>
                </el-form-item>
				<el-form-item label=""><el-button @click="search" type="primary" icon="Search"
				        v-show="hasPermission(this.$route.name, 'Search')">查询</el-button></el-form-item>
                
            </el-form>
        </div>
		<p>打包费由集花宝系统根据用户订单内的花材数量按以下配置收取</p>

        <div class="table">
            <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage">
                <el-table-column  width="200" align="center" label="序号" >
                   <template #default="scope">
                       <span v-text="getIndex(scope.$index)"></span>
                   </template>
                </el-table-column>
                
                <el-table-column width="220"  prop="min_product_count" label="区间内最低商品数量"  >
					
				</el-table-column> 
				
				<el-table-column width="220"  prop="max_product_count" label="区间内最多商品数量">
					
				</el-table-column>
				
                <el-table-column width="150" prop="fee" label="收费"></el-table-column>
                
                <el-table-column min-width="50" label="状态">
                    <template #default="scope">
						<el-button v-if="scope.row.status" type="primary" round size="small" @click="manageStatus(scope.row,'stop')">使用中</el-button>
						<el-button v-if="!scope.row.status" type="warning" round size="small" @click="manageStatus(scope.row,'start')">未使用</el-button>
                        <!-- <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949"
                            @change="changeStatus(scope.row)">
                        </el-switch> -->
                    </template>
                </el-table-column>
                
            </el-table>
        </div>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    
	
		
		
    </div>
</template>
<script>

import Pagination from "@/components/Pagination";
import { dateFormats, getTableHeight } from "@/utils/util";
import { UsersAgentUsers, UsersAgentUsersDelete,manageAgentStatus,editAgentSalesmanApi } from '@/api/api'
import{loadAdminListApi} from "@/api/system"
import { operateSuperAgentAPi } from '@/api/super_agent.js'

import {loadPackagingListApi ,managePackagingFeeApi,newPackagingFeeApi} from '@/api/mall'

export default {
    components: {
        Pagination,
        
    },
    name: 'packagingFeeManage',
    data() {
        return {
            isFull: false,
            tableHeight: 500,
            loadingPage: false,
            formInline: {
                page: 1,
                limit: 10,
	
            },
            defaultImg: require('../../assets/img/avatar.jpg'),
            pageparm: {
                page: 1,
                limit: 10,
                total: 0
            },
            statusList: [
                { id: 1, name: '正常' },
                { id: 0, name: '禁用' }
            ],
            identityList: [
                { id: 0, name: '普通用户' },
                { id: 1, name: '会员' },
            ],
            timers: [],
            tableData: [],
			data_form:{
				id:0,
				min_product_count:1,
				max_product_count:999,
				fee:0,
				appid:"",
			},
            show_set_dialog: false,
			app_list:[{appid:"1",app_name:"集花宝"}]
			
			
			
	
        }
    },

    methods: {
		
		confirmUpdateSendType(){
			
			let msg=""
			if(this.sendtype_form.can_self_pickup){
				msg="确定该地区用户的花材可以直接发给用户嘛？直发给用户的订单该地区代理可以获得原收入的"+this.sendtype_form.self_pickup_income_ratio+"%"
			}else{
				msg='确定该地区用户的花材只能经过代理配送吗？'
			}
			var that=this
			that.$confirm(msg,"提示",{
				closeOnClickModal: false,
				type: "warning"
			}).then(res=>{
				
				updateAgentSendTypeApi({agent_id:that.sendtype_form.agent_id,can_self_pickup:that.sendtype_form.can_self_pickup,self_pickup_income_ratio:that.sendtype_form.self_pickup_income_ratio}).then(res=>{
					if(res.code==2000){
					 that.$message.success(res.msg)
					 that.getData()
					 that.show_update_send_type=false
					}else{
						 that.$message.warning(res.msg)
					}
				})
				
			}).catch(()=>{
				
			})
				
			
			
		},
		
		
        // 表格序列号
        getIndex($index) {
            // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
            return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
        },
        setFull() {
            this.isFull = !this.isFull
            window.dispatchEvent(new Event('resize'))
        },
		
		
		resetDataForm(){
			this.data_form={
				id:0,
				min_product_count:1,
				max_product_count:1,
				fee:0,
				
			}
		},
       
        handelAddClick() {
			this.resetDataForm()
            this.show_set_dialog=true
        },
		saveSetting(){
		  	let that = this
			let msg=""
			if (this.data_form.id>0){
				msg="确定修改打包费为"+this.data_form.fee+"元吗？"
			}else{
				msg='确定新增打包费设置吗？'
			}
			
		  	that.$confirm(msg,"提示", {
		  	    closeOnClickModal: false,
				type: "warning"
		  	}).then(res => {
				let param={
					id:parseInt(that.data_form.id),
					min_product_count:parseInt(that.data_form.min_product_count),
					max_product_count:parseInt(that.data_form.max_product_count),
					fee:that.data_form.fee,
				}
				
				if(that.data_form.id>0){
					
					newPackagingFeeApi(param).then(res=>{
						if(res.code==1){
							that.getData()
							that.$message.success(res.msg)
							that.show_set_dialog=false
						}else{
							that.$message.warning(res.msg)
						}
					})
				}else{
					newPackagingFeeApi(param).then(res=>{
						if(res.code==1){
							that.getData()
							that.$message.success(res.msg)
							that.show_set_dialog=false
						}else{
							that.$message.warning(res.msg)
						}
					})
				}
				that.resetDataForm()
		  	    
		  	}).catch((err) => {
		  	   console.log(err)
		  	})
		},
        handleEdit(row, flag) {
			
            if (flag == 'edit') {
				
               this.show_set_dialog=true
			   this.data_form.id=row.id
			   this.data_form.max_product_count=row.max_product_count
			   this.data_form.min_product_count=row.min_product_count
			   this.data_form.fee=row.fee
			  
            }
           
        },
		changeStatus(row){
			
			let act_type=""
			let that=this
			if(!row.status){
				
				act_type="stop"
			}
			if(row.status){
				
				act_type="start"
			}
			
			this.manageStatus(row,act_type)
			
		},
		manageStatus(row,act_type){
			let id=row.id
			let msg=""
			
			if(act_type=="start"){
				
				msg="确定使用该打包费设置吗?该设置用户订单内花材数量在"+ row.min_product_count+"-"+row.min_product_count+"扎之内（包含）将预收打包费："+row.fee
			}
			if(act_type=="stop"){
				msg="确定停止该打包费设置吗"
			}
			if (act_type=="del"){
				msg="确定删除该打包费设置吗"
			}
			let that=this
			
			
			that.$confirm(msg,"提示", {
			    closeOnClickModal: false,
				type: "warning"
			}).then(res => {
				   managePackagingFeeApi({id:id,act_type:act_type}).then(res=>{
				   	if(res.code==1){
				   		that.$message.success(res.msg)
				   		that.getData()
				   	}else{
				   		that.$message.warning(res.msg)
				   	}
				   })
				}).catch(()=>{
					
				})
			
			
			
			},
		
        /**
         * 从URL里下载文件
        */
        // 下载文件
        downloadFileURL(url) {
            var iframe = document.createElement("iframe")
            iframe.style.display = "none";
            iframe.src = url;
            document.body.appendChild(iframe);
        },
        exportDataBackend() {
            var params = {
                page: 1,
                limit: 9999,
            }
            // UsersUsersExportexecl(params).then(res => {
            //     if (res.code == 2000) {
            //         this.downloadFileURL(res.data.data)
            //         //this.$message.warning(res.data.data)
            //     }
            // })
        },
        callFather(parm) {
            this.formInline.page = parm.page
            this.formInline.limit = parm.limit
            this.getData()
        },
        search() {
            this.formInline.page = 1
            this.formInline.limit = 10
            this.getData()
        },
        //获取列表
        async getData() {
            this.loadingPage = true
            loadPackagingListApi(this.formInline).then(res => {
                this.loadingPage = false
                if (res.code == 1) {
					
                    this.tableData = res.data.data_list
                    
                    this.pageparm.total = res.data.total;
                }
            })
        },

        timeChange(val) {
            if (val) {
                this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
                this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
            } else {
                this.formInline.beginAt = null
                this.formInline.endAt = null
            }
            this.search()
        },
        // 计算搜索栏的高度
        listenResize() {
            this.$nextTick(() => {
                this.getTheTableHeight()
            })
        },
        getTheTableHeight() {
            let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
            tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
            this.tableHeight = getTableHeight(tabSelectHeight)
        },
		updateSalesman(){
			var that=this
			let msg=""
			
			if(this.salesman_form.sales_admin_id<1 || !this.salesman_form.sales_admin_id){
				msg="确定移除关联的后台运营人员吗？"
				this.salesman_form.sales_admin_id=0
			}
			if(this.salesman_form.sales_admin_id>0){
				msg="确定修改关联的后台运营人员吗？"
			}
			that.$confirm(msg,"提示", {
			    closeOnClickModal: false
			}).then(res=>{
				editAgentSalesmanApi({agent_id:that.salesman_form.agent_id,admin_id:that.salesman_form.sales_admin_id}).then(req_res=>{
					if (req_res.code==2000){
						that.$message(req_res.msg)
						that.getData()
						that.is_show_edit_sales_dialog=false
					}
				})
			}).catch()
			
		}
		
		

    },
    created() {
        this.getData()
		
    },
    mounted() {
        // 监听页面宽度变化搜索框的高度
        window.addEventListener('resize', this.listenResize);
        this.$nextTick(() => {
            this.getTheTableHeight()
        })
    },

    unmounted() {
        // 页面销毁，去掉监听事件
        window.removeEventListener("resize", this.listenResize);
    },
}
</script>
